/** 简体中文 */
export default {
  layout: {
    language: '语言：{name}',
    loginBtn: '立即登录/ 注册 | 登录 / 注册 | 立即登录',
    loginTip:
      '请注册 / 登录为会员\n以观看更多内容 | 请注册 / 登入以观看更多内容 | 请注册/登入以观看更多内容',
    cancel: '取消 | 稍后',
    broadcastTitle: '特别公告',
    broadcastBtn: '确定',
    broadcastLabel: '不再显示此消息。',
    areaNotice: {
      message: '此页面并非您当前地区的页面。\n您要切换到当前地区吗？',
      jump: '去吧！',
      messageMo: '此页面不适用于您当前的地区。 \n您要返回当前地区的主页吗？',
      jumpMo: '请跳转到我的主页'
    },
    tutorial: '教学',
    fqa: '常见问题',
    downloadApp: '下载',
    contactUs: '联络我们',
    infoSupport: '支持与帮助',
    setting: '设置'
  },
  home: {
    name: '主页',
    more: '更多',
    errorPage: '进入错误页面',
    seo: {
      title: () => 'TVB Anywhere | 海外官方网站',
      description:
        '带给您TVB最新最热门的电视剧、综艺节目、 和直播等多种娱乐选择，更有独家幕后花絮、访问、纪录片等独家内容，让您身临其境，畅享观影体验！'
    },
    episode1: '第{n}集',
    episode2: '更新至第{n}集',
    episode3: '全{n}集',
    episode4: '更新至{n}',
    episode5: '全{n}期',
    downloadDesc: '立即下载我们的应用程序以享受更多功能！'
  },
  vodplayer: {
    name: '点播页',
    episode: '第',
    ji: '集',
    qi: '期',
    update: '更新至',
    wanjie: '全',
    thisEpisode: '本集内容',
    synopsis: '节目内容',
    artist: '演员',
    openinapp: '打开App',
    basicmember: '免费会员',
    total: '总共',
    role: '饰',
    recommend: '推荐',
    nowPlaying: '正在播放'
  },
  chlplayer: {
    name: '直播页',
    channel: '直播区',
    schedule: ' 节目表'
  },
  vodzone: {
    name: '点播区',
    more: '更多',
    less: '收起',
    seo: {
      description: '享受TVB原创及其他外购剧集及综艺节目'
    }
  },
  chlzone: {
    name: '直播频道区',
    empty: '没有可播放的频道。'
  },
  player: {
    recommend: '推荐',
    fullScreen: '全屏显示节目表',
    cast: '演员',
    as: '饰',
    episodes: '集数',
    playing: '播放中',
    nowplaying: '正在播放',
    episodeContents: '本集內容',
    synopsis: '节目内容',
    openinapp: '在APP中打开',
    basicmember: '免费会员',
    copied: '已复制',
    hour: '小时',
    day: '天',
    error: {
      nologin: '登录 / 注册',
      more: '了解更多',
      free: '播放最新免费集数',
      chlEmpty: '没有可播放的频道。​'
    },
    lastDayToWatch: '下架日期 ',
    liveDescription: ' = 7日 / 即时3小时回看'
  },
  epg: {
    name: '节目表',
    tip: '仅显示有节目表的频道',
    live: '直播中',
    playing: '回看中',
    today: '今天',
    seo: {
      title: () => '直播 | TVB Anywhere'
    }
  },
  error: {
    title404: '找不到页面',
    des404: '抱歉，您要查找的页面可能在您当前的位置不可用或不存在。',
    title500: '伺服器错误',
    des500: '抱歉，我们遇到了错误而未能处理您的请求。如果问题持续，请向 cs@tvbanywhere.com 查询。',
    backHome: '返回主页',
    empty: '未能找到相关信息',
    titleUnsup: '改善您的浏览体验',
    desUnsup: '您正在使用我们不支持的网络浏览器。尝试使用以下浏览器之一以获得更好的体验。'
  },
  setting: {
    title: '设定',
    video: {
      quality: '视频素质',
      auto: '自动',
      high: '高',
      medium: '中',
      low: '低'
    },
    audio: {
      channel: '声道语言',
      cantonese: '广东话',
      mandarin: '普通话',
      english: '英语',
      thai: '泰语',
      vietnamese: '越南语',
      audio1: '声道 1',
      audio2: '声道 2'
    },
    subtitle: {
      language: '字幕语言',
      simplifiedChinese: '简体中文',
      traditionalChinese: '繁体中文',
      bahasaIndonesia: '印尼文',
      english: '英文',
      vietnamese: '越南文',
      malaysian: '马来语',
      off: '关闭'
    },
    automaticallyPlayNextEpisode: '自动播放下一集',
    fontSize: '字体大小'
  },
  search: {
    search: '搜索',
    history: '搜索历史',
    hot: '热门关键字',
    audio: '影片声道语言搜索',
    subtitle: '影片字幕语言搜索',
    channels: '直播频道',
    programmes: '节目',
    recommendation: '推荐',
    empty: '找不到相关结果',
    seo: {
      title: () => '搜索 | TVB Anywhere',
      description: '搜索演员名称、剧集名称、声音或字幕语言'
    }
  },
  history: {
    name: '观看记录',
    empty: '没有任何观看记录',
    delete: '删除',
    select: '全选',
    deselect: '取消全选',
    more: '查看更多',
    seo: {
      title: () => '观看记录 | TVB Anywhere',
      description: '观看记录'
    }
  },
  favourite: {
    name: '我的收藏',
    empty: '没有收藏任何节目',
    delete: '删除',
    select: '全选',
    deselect: '取消全选',
    more: '查看更多',
    added: '添加到我的收藏',
    removed: '从我的收藏中移除',
    toast: {
      added: '添加到\n我的收藏',
      removed: '从我的收藏\n中移除'
    },
    down: '已下架',
    seo: {
      title: () => '我的收藏 | TVB Anywhere',
      description: '我已收藏剧集'
    }
  },
  static: {
    privacy: '隐私政策',
    privacyNoticeFull: '完整私隐声明(欧盟)',
    privacyNoticeOverview: '私隐声明概览(欧盟)',
    cookiesPolicy: 'Cookies政策',
    cookiesSetting: 'Cookies设定',
    aboutUs: '关于我们',
    tutorial: '教学',
    tutorialStb: 'TVB Anywhere 机顶盒',
    tutorialApp: 'TVBAnywhere+应用程式跨平台',
    faq: '常见问题',
    contactUs: '联系我们',
    partnersDealers: '销售伙伴/海外分销商联络资料',
    termsOfService: '使用条款',
    paymentTermsAndConditions: '订阅条款及细则',
    jetsoTermsAndConditions: '3zone 服务条款'
  },
  subscribe: {
    sectionA: {
      title: '随时随地\n尽享源源不绝的精彩剧集和综艺节目',
      travel_pass_title:
        '担心去旅行时没法追剧？<span style="color: #ff4b03;">TVB Anywhere旅游通</span>帮您随时随地\n紧贴TVB精彩内容超过40,000小时点播节目，服务范围遍布全球*，令您旅途添上无尽精彩！',
      desc: '立即选择你的服务计划',
      travel_pass_desc: '*此服务不适用于中国内地、香港、澳门、美国及加拿大',
      tip: '订阅一年计划即享有超過15% 折扣优惠 （对比每月连续订阅月费计划12次）',
      month: '月 | {n}个月 | {n}个月',
      daily: '天 | {n}天',
      year: '年',
      original: '原价',
      off: '折扣'
    },
    sectionB: {
      add: '额外\n加购',
      btn: '立即订阅',
      click: '按此',
      more: '了解更多'
    }
  }
}
